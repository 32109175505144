import React, {useState, useEffect} from "react"
import getFirebase from 'src/utilities/firebase'
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import SupportHelmet from 'src/components/SupportHelmet'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { identify, trackPage, } from 'src/utilities/analytics'
import { Link, navigate } from "gatsby"
import WorkHighFive from 'src/images/work-high-five.jpg'
import ArrowRight from 'src/images/icons/arrow-right.svg'


const Welcome = ({currentUser}) => {
  const arrowRightStyle = {
    width: 17,
    height: 17,
  }
  const ctaDestinationURL = currentUser ? '/resumes' : '/resume-templates'
  return (
    <section class="pt-4 pt-md-11">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-5 col-lg-6 order-md-2">
            <img src={WorkHighFive} class="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" alt="..." data-aos="fade-up" data-aos-delay="100"/>
          </div>
          <div class="col-12 col-md-7 col-lg-6 order-md-1" data-aos="fade-up">
            <h1 class="display-3 text-center text-md-left">
              Beautiful resume<br/>
              in 5 minutes<br/>
            </h1>
            <p class="lead text-center text-md-left text-muted mb-6 mb-lg-8">
              Build a beautiful, modern resume in 5 minutes. <br/>
              Fill out a form, choose from ten professionally-designed templates, and download as a PDF.<br/>
              Try now for free!
            </p>
            <div class="text-center text-md-left">
              <Link to={ctaDestinationURL} class="btn btn-primary shadow lift mr-1">
                Build your resume <ArrowRight style={arrowRightStyle} fill='white' className="fe fe-arrow-right d-none d-md-inline ml-3"/>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </section>
    )
}

const Feature = ({title, body, image}) => {
  return (
    <div class="col-12 col-md-4" data-aos="fade-up">
    <div class="icon text-primary mb-3">
      {/* image */}
    </div>
    <h3>
      {title}
    </h3>
    <p class="text-muted mb-6 mb-md-0">
      {body}
    </p>
  </div>
  )
}

const Features = () => {
  return (
    <section class="py-8 py-md-11">
      <div class="container">
        <div class="row">
          <Feature
            title='Win Interviews'
            body='98% of job seekers are eliminated in resume screening. Stand out from the crowd with a professionally-designed resume.'
          />
          <Feature
            title='Easy and fast'
            body='Our software builds your resume from one of ten professionally-designed templates. Just fill in your details.'
          />
          <Feature
            title='Try now for free'
            body='You can start building your resume and preview our templates right now. Completely free, no credit card needed!'
          />
        </div>
      </div>
    </section>
    )
}

const Beta = () => {
  const firebase = getFirebase();
  const [currentUser, setCurrentUser] = useState(null)
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      identify(user)
    });
    }, [firebase]);
  useEffect(() => {
    trackPage('Beta Landing')
  }, [])
  return (
    <Layout
      onSignInSuccess={() => navigate('/resumes')}
    >
      <SEO title='Build a Beautiful Resume in 5 Minutes' />
      <SupportHelmet/>
      <AnalyticsHelmet/>
      <Welcome {...{currentUser}}/>
      <Features/>
    </Layout>
  )
}

export default Beta
